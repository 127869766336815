import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{ref:"date",attrs:{"disabled":_vm.readonly,"close-on-content-click":false,"transition":"scale-transition","autocomplete":"off","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"rules":_vm.rules,"dense":_vm.dense,"label":_vm.label,"persistent-hint":"","outlined":_vm.outlined,"append-icon":_vm.appendIcon,"readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on)),_c('div',{staticClass:"mt-n5 mb-5"},[_vm._t("message")],2)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){_vm.dialog = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }