<template>
  <v-menu
    :disabled="readonly"
    ref="date"
    v-model="dialog"
    :close-on-content-click="false"
    transition="scale-transition"
    autocomplete="off"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :rules="rules"
        :dense="dense"
        v-model="date"
        :label="label"
        persistent-hint
        :outlined="outlined"
        :append-icon="appendIcon"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
      <div class="mt-n5 mb-5">
        <slot name="message"> </slot>
      </div>
    </template>
    <v-date-picker v-model="date" no-title @input="dialog = false"></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    appendIcon: {
      type: String,
      default: 'mdi-calendar'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    initialize: {
      type: Boolean,
      default: true
    },
    rules: {
      type: [Object, Array]
    },
    label: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    date: '',
    dialog: false
  }),
  beforeMount() {
    this.date = '';
    this.setDate();
  },
  methods: {
    setDate() {
      if (this.value != undefined) {
        this.date = this.value;
      } else {
        this.date = '';
      }
    },
    handleChange() {
      this.$emit('input', this.date);
    }
  },
  watch: {
    date: {
      handler: 'handleChange'
    },
    value: {
      immediate: true,
      handler: 'setDate'
    }
  }
};
</script>

<style></style>
